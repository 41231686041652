<script setup>


let ribbonForm, leadGenFields

const { getLeadGenLocal, setLeadGenLocal, leadGenData, ribbonFormData } = useLeadGenData();
// Getting the data from the server cache first
// const { data } = await getLeadGenLocal();

// if (data.value.statusCode == 500 || data.value.body == null) {
//   // If the data does not exists then fetch from the API directly
//   const { data: leadGen } = await leadGenData();

//   ribbonForm = leadGen.value.ribbonForm
//   leadGenFields = leadGen.value.leadGenFields

//   // Updating the server cache once we have the data refreshed
//   const { data: updated } = await setLeadGenLocal(leadGen);
// } else {
//   ribbonForm = data.value.body.ribbonForm
//   leadGenFields = data.value.body.leadGenFields
// }
const { data: leadGen } = await leadGenData();
const { data: ribbon } = await ribbonFormData();

const props = defineProps({
  ribbonTags: Array,
})

const config = useRuntimeConfig()

ribbonForm = ribbon.value.ribbonForm
leadGenFields = leadGen.value.leadGenFields

// Model variables and utility variables
let formData = ref(null), inputFirstName = ref(''), inputEmail = ref(''), inputPostalCode = ref(''), errorFound = ref(false), errMsg = ref(''),
  nameError = ref(false), emailError = ref(false), postalcodeError = ref(false)

// Array of ages and birth years
let ages = []
let currentYear = new Date
for (let i = 7; i < 20; i++) {
  ages.push({ value: (parseInt(currentYear.getFullYear()) - i), label: i })
}

defineExpose({
  submitHandler
})

let backgroundColor = ribbonForm.ribbonBackground
backgroundColor = backgroundColor.toLowerCase().replace(/\s+/g, '-')
let learningStylesLabels = leadGenFields.learningStylesCheckboxes

// Form submit handler
async function submitHandler() {

  const formObject = { leadTypeID: 67000 } // LeadTypeID is the ID for the brochure request
  let selectedLearningStyles = [], defaultLearningStyles = []
  // Creation of the JSON object that will be sent to the API endpoint
  for (let element of formData.value.elements) {
    // Element must have a name, a value and not be a multi-dropdown checkbox
    if (element.name !== '' && element.value !== '' && !element.classList.contains('multi-checkbox')) {
      // If is a checkbox we make sure they get sent as an array
      if (element.type === 'checkbox') {
        if (element.name === 'learningStyleTypeIDs') {
          defaultLearningStyles.push(element.value)

          if (element.checked == 1) {
            selectedLearningStyles.push(element.value)
          }

        } else {
          !Array.isArray(formObject[element.name]) ? formObject[element.name] = [element.value] : formObject[element.name].push(element.value)
        }
      } else {
        if (element.name === 'studentBirthYears') {
          formObject['students'] = element.value.split(',').map(year => ({
            studentFirstName: "",
            studentBirthYear: parseInt(year, 10),
          }))
        } else {
          // Any other form element will be sent as is
          formObject[element.name] = element.value
        }
      }
    }
  }

  if (selectedLearningStyles.length > 0) {
    formObject['learningStyleTypeIDs'] = selectedLearningStyles
  } else if (defaultLearningStyles.length > 0) {
    formObject['learningStyleTypeIDs'] = defaultLearningStyles
  } else {
    // This is just in case they don't select the field on the page to show so it has default values.
    formObject['learningStyleTypeIDs'] = [405000, 405001, 405002, 405003]
  }

  // Last elements to send
  formObject.sourcePageUrl = window.location.href
  // tagsArray
  if (props.ribbonTags && props.ribbonTags.length) {
    let tags = []
    if (isObject(props.ribbonTags[0])) {
      for (let i = 0; i < props.ribbonTags.length; i++) {
        tags.push(props.ribbonTags[i].tag)
      }
    } else if (props.ribbonTags.length) {
      for (let i = 0; i < props.leadGenForm.tagsArray.length; i++) {
        if (isObject(props.leadGenForm.tagsArray[i])) {
          tags.push(props.leadGenForm.tagsArray[i].tag)
        } else {
          tags.push(props.leadGenForm.tagsArray[i])
        }
      }
    } else {
      tags = []
    }
    formObject['tagsArray'] = tags
  }

  function isObject(objValue) {
    return objValue && typeof objValue === 'object' && objValue.constructor === Object;
  }

  const cookiesArr = ['utm_source', 'utm_medium', 'utm_term', 'utm_campaign', 'utm_content', 'utmSiteVisitDate', 'User_Agent', 'DestinationUrl']
  
  // Update form object with cookies parameters
  for (let cookieName in cookiesArr) {
    let cookie = useCookie(cookieName)
    if (!!cookie && cookie.value != '') {
      formObject[cookieName] = cookie.value
    }
  }
  const hashedData = hashObject(formObject); 
  dataLayer.push({
    'event': 'formSubmitted',
    'leadsUserData': hashedData
  });
  // Transform the object into a JSON
  const formJSON = JSON.stringify(formObject)

  // Sending a record lead
  const nuxtApp = useNuxtApp()
  let apiResponse = await nuxtApp.postData(config.public.pulseAPILeadGenEndpoint, formJSON)

  if (apiResponse.status != 200) {
    errMsg.value = (!!apiResponse.message ? apiResponse.message : "There was a problem with the server response" )
  } else {
    window.location.href = '/brochurerequest-thankyou-digital';
  }
  
}

function checkErrors() {
  if (nameError.value || emailError.value || postalcodeError.value) {
    errorFound.value = true
  } else if (!nameError.value && !emailError.value && !postalcodeError.value) {
    errorFound.value = false
  }
}

onMounted(() => {
  const section = document.getElementById('ribbon-form-section') 
  const fieldsets = section.getElementsByTagName('fieldset');
  fieldsets[0].removeAttribute('disabled')
})
</script>

<template>
  <section id="ribbon-form-section" :class="'ribbon-form-section bg-' + backgroundColor">
    <div class="container">
      <div class="row">
        <div class="col-12 ribbon-form-inner-section">
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="image-container">
                <NuxtImg class="ribbon-image d-none d-md-block" :src="ribbonForm.ribbonImage.url"
                  :alt="ribbonForm.ribbonImageTitle" />
                <NuxtImg class="ribbon-image d-md-none" :src="ribbonForm.mobileRibbonImage.url"
                  :alt="ribbonForm.ribbonImageTitle" />
              </div>
            </div>
            <div class="col-12 col-md-8">
              <div class="ribbon-form-container">
                <UIHeading :titleComponent="ribbonForm.titleComponent" :subtitleComponent="ribbonForm.subtitleComponent"
                  :titleClass="'brochure-ribbon-title'" :subtitleClass="'brochure-ribbon-subtitle'" />
                <form class="ribbon-form" ref="formData" @submit.prevent="submitHandler">
                  <fieldset disabled="disabled">
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <div class="form-floating input-container">
                          <input type="text" id="firstName" class="form-control form-input" name="firstName" required v-model="inputFirstName" :placeholder="leadGenFields.namePlaceholderText" @focusout="checkErrors()">
                          <label for="firstName" v-html="nameError ? leadGenFields.nameErrorMessage : leadGenFields.namePlaceholderText"></label>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-floating input-container">
                          <input type="email" id="emailAddress" class="form-control form-input" name="emailAddress" required v-model="inputEmail" :placeholder="leadGenFields.emailPlaceholderText" @focusout="checkErrors()">
                          <label for="emailAddress" v-html="nameError ? leadGenFields.emailErrorMessage : leadGenFields.emailPlaceholderText"></label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form-floating input-container">
                          <input type="text" id="postalCode" class="form-control form-input" name="postalCode" v-model="inputPostalCode" :placeholder="leadGenFields.zipcodePlaceholderText" @focusout="checkErrors()">
                          <label for="postalCode" v-html="nameError ? leadGenFields.zipcodeErrorMessage : leadGenFields.zipcodePlaceholderText"></label>
                        </div>
                      </div>
                      <div class="col-6">
                        <UIMultiSelectDropdown :inputName="'studentBirthYears'" :options="ages"
                          :multiSelectLabel="leadGenFields.studentAgeTitle" />
                      </div>
                    </div>
                    <div class="form-heading checkbox-heading">
                      <div class="checkbox-title">{{ leadGenFields.titleProgramType }}</div>
                      <div class="checkbox-subtitle">{{ leadGenFields.subTitleProgramType }}</div>
                    </div>
                    <div class="checkbox-group row">
                      <div class="checkbox-container col-12 col-md-6"
                        v-if="!!learningStylesLabels.OnCampusSummerProgramsLabel">
                        <input type="checkbox" name="learningStyleTypeIDs" class="form-checkbox" value="405000">
                        <span class="checkmark form-checkmark"></span>
                        <span class="checkbox-label"> {{ learningStylesLabels.OnCampusSummerProgramsLabel }}</span>
                      </div>
                      <div class="checkbox-container col-12 col-md-6" v-if="!!learningStylesLabels.OnlineCampsClassesLabel">
                        <input type="checkbox" name="learningStyleTypeIDs" class="form-checkbox" value="405001">
                        <span class="checkmark form-checkmark"></span>
                        <span class="checkbox-label"> {{ learningStylesLabels.OnlineCampsClassesLabel }}</span>
                      </div>
                      <div class="checkbox-container col-12 col-md-6" v-if="!!learningStylesLabels.SmallGroupOnlineLabel">
                        <input type="checkbox" name="learningStyleTypeIDs" class="form-checkbox" value="405002">
                        <span class="checkmark form-checkmark"></span>
                        <span class="checkbox-label"> {{ learningStylesLabels.SmallGroupOnlineLabel }}</span>
                      </div>
                      <div class="checkbox-container col-12 col-md-6"
                        v-if="!!learningStylesLabels.OneOnOnePrivateLessonsLabel">
                        <input type="checkbox" name="learningStyleTypeIDs" class="form-checkbox" value="405003">
                        <span class="checkmark form-checkmark"></span>
                        <span class="checkbox-label"> {{ learningStylesLabels.OneOnOnePrivateLessonsLabel }}</span>
                      </div>
                    </div>
                    <div class="form-error-message" v-html="errMsg" v-if="!!errMsg"></div>
                    <button :class="'btn display btn-primary' + (errorFound ? ' disabled' : '')"
                      type="submit">{{ ribbonForm.primaryCTAText }}</button>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.brochure-ribbon-title {
  font-weight: bold;
  margin-bottom: 20px;
}

.brochure-ribbon-subtitle {
  font-weight: bold;
}

.ribbon-form-section {
  background-color: $color-secondary;
  color: $color-body-text;
  padding: 50px 0;

  form fieldset[disabled^="disabled"] {
    .form-floating.input-container {
      .form-input.fake-input.form-select {
        background-color: #e9ecef !important;
      }
    }
  }

  &.bg-white {
    background-color: $color-secondary;
  }

  &.bg-light-grey {
    background-color: $color-accent;
  }

  &.bg-grey {
    background-color: $color-gray;
  }

  &.bg-black {
    background-color: $color-primary;
  }

  .ribbon-form-inner-section {
    background: $color-secondary;
    border-radius: 24px;
    padding: 40px 30px;
    border: solid 1px $color-light-gray;

    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .ribbon-image {
        max-width: 100%;
        width: auto;
        height: auto;
        margin-bottom: 20px;
      }
    }

    .ribbon-form-container {
      .ribbon-form {

        .checkbox-group {
          .checkbox-container {
            position: relative;
            .form-checkbox {
              width: 100% !important;
            }
            .checkmark{
              position: absolute;
              left: 15px;
              top: 3px;
            }
            .checkbox-label{
              margin-left: 25px;
            }
          }
        }

        .btn[type=submit] {
          margin-top: 10px;
          &:disabled {
            opacity: 0.65;
          }
        }
      }
    }

    .form-error-message {
      padding: 12px 13px;
      display: block;
      text-align: left;
      font-size: $font-size-base;
      border: solid 1px;
      margin-top: 10px;
      color: red;
    }
  }
}

.form-disclaimer {
  padding-top: 20px;
  font-size: $font-size-tiny;

  a {
    color: $color-body-text;
    font-weight: bold;
  }
}

@include media-breakpoint-up(sm) {
  // small screens
}

@include media-breakpoint-up(md) {

  // medium screens
  .ribbon-form-section {
    .image-container {
      .ribbon-image {
        margin-bottom: 0;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  // large screens
}

@include media-breakpoint-up(xl) {
  // extra large screens
}</style>
